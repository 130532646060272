import {useRef, useState} from "react";
import "./register.scss";

export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailRef = useRef();
  const passwordRef = useRef();

  const handleStart = () => {
    setEmail(emailRef.current.value);
  };
  const handleFinish = () => {
    setPassword(passwordRef.current.value);
  };
  return (
    <div className="register">
      <div className="top">
        <div className="wrapper">
          <img
            className="logo"
            src="https://cirep.be/wp-content/uploads/2022/02/CIReP-definitif-360-400x212.png"
            alt=""
          />
          <button className="loginButton" onClick={handleStart}>Se connecter </button>

        </div>
      </div>
      <div className="container">
        <h1>Un catalogue de contenu vidéo et visuels sur le polyhandicap.</h1>
        <h2>Ou que vous soyez, profitez-en.</h2>
        <p>
          Envie d'accéder au contenu? Entrez votre adresse mail et nous vous recontacterons
        </p>
        {!email ? (
          <div className="input">
            <input type="email" placeholder="email address" ref={emailRef} />
            <button className="registerButton" onClick={handleStart}>
              Envoyer
            </button>
          </div>
        ) : (
          <form className="input">
            <input type="password" placeholder="password" ref={passwordRef} />
            <button className="registerButton" onClick={handleFinish}>
              Envoyé
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
