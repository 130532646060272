import "./app.scss"
import Watch from "./pages/watch/Watch";
import {Route, Routes,} from "react-router-dom";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Home from "./pages/home/Home";

function App() {
    return (

            <Routes>

                <Route path="/" element={<Login/>} />
                <Route path="/watch" element={<Watch/>} />
                <Route path="/register" element={<Register/>} />
                <Route path="/login" element={<Login/>} />
                <Route path="/home" element={<Home/>} />
            </Routes>
    );
}
export default App;