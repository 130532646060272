import "./listItem.scss";
import {
  PlayArrow,
  Add,
  ThumbUpAltOutlined,
  ThumbDownOutlined, ArrowBackOutlined,
} from "@material-ui/icons";
import { useState } from "react";
import {Link} from "react-router-dom";

export default function ListItem({ index,url,thumbnail,trailerThumb }) {
  const [isHovered, setIsHovered] = useState(false);
  const trailer =
    trailerThumb+".mp4";
  console.log(trailerThumb);
  return (
    <div
      className="listItem"
      style={{ left: isHovered && index * 225 - 50 + index * 2.5 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={thumbnail+".png"}
        alt=""
      />
      {isHovered && (
        <>
          <video src={trailer} autoPlay={true} loop />
          <div className="itemInfo">
            <div className="icons">
              {/*<PlayArrow className="icon"/>*/}
              <Link to='/watch'  state={{ url: url}}> <PlayArrow className="icon"/></Link>
              <Add className="icon" />

            </div>
            <div className="itemInfoTop">
              <span>1 heure 14 minutes</span>
              <span className="limit">Polyhandicap</span>
              <span>Communication</span>
            </div>
            <div className="desc">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Praesentium hic rem eveniet error possimus, neque ex doloribus.
            </div>
            <div className="genre">Polyhandicap</div>
          </div>
        </>
      )}
    </div>
  );
}
