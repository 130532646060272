import { ArrowBackOutlined } from "@material-ui/icons";
import "./watch.scss";
import {Link, Navigate, NavLink,useParams,useLocation  } from "react-router-dom";
import {useEffect} from "react";

export default function Watch(props) {
    useEffect(() => {
        // define a custom handler function
        // for the contextmenu event
        const handleContextMenu = (e) => {
            // prevent the right-click menu from appearing
            e.preventDefault()
        }

        // attach the event listener to
        // the document object
        document.addEventListener("contextmenu", handleContextMenu)

        // clean up the event listener when
        // the component unmounts
        return () => {
            document.removeEventListener("contextmenu", handleContextMenu)
        }
    }, [])

    const location = useLocation();
    const { url } = location.state;


  return (

    <div className="watch">
      <div className="back">
          <Link to='/home'> <ArrowBackOutlined /></Link>

      </div>
      <video
        className="video"
        autoPlay
        progress
        controls
        controlsList="nodownload"
        src={"../"+url+".mp4"}
      />
    </div>
  );
}
function handleClick() {
    console.log("coucou");
}


