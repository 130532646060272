import { ArrowDropDown, Notifications, Search } from "@material-ui/icons";
import { useState } from "react";
import "./navbar.scss";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };
  return (
    <div className={isScrolled ? "navbar scrolled" : "navbar"}>
      <div className="container">
        <div className="left">
          <img
            src="https://cirep.be/wp-content/uploads/2022/02/CIReP-blanc.png"
            alt=""
          />
          <span>Accueil</span>
          <span>Vidéo</span>
          <span>Documents</span>
          <span>Nouveautés</span>
          <span>Ma liste</span>
        </div>
        <div className="right">
          <Search className="icon" />
          <span>James</span>
          <Notifications className="icon" />
          <img
            src="https://media.licdn.com/dms/image/C5603AQGBeBUEYPb2vQ/profile-displayphoto-shrink_800_800/0/1603104810285?e=1684972800&v=beta&t=ue1sK79aL-Fzp0ZFZHpFFuMz2hT8nutKatxA-75ingA"
            alt=""
          />
          <div className="profile">
            <ArrowDropDown className="icon" />
            <div className="options">
              <span>Paramètres</span>
              <span>Déconnexion</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
