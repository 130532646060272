import "./login.scss";
import {ArrowBackOutlined} from "@material-ui/icons";
import {Link, Navigate, Route, useNavigate} from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();


    if(isCorrect(e.target.elements.mail.value,e.target.elements.password.value))
    {
      navigate('/home', {replace: true});
    }

  };
  return (
    <div className="login">
      <div className="top">
        <div className="wrapper">
          <img
            className="logo"
            src="https://cirep.be/wp-content/uploads/2022/02/CIReP-definitif-360-400x212.png"
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <form onSubmit={onSubmit}>
          <h1>Se connecter</h1>
          <input type="email" name="mail"placeholder="adresse mail" />
          <input type="password" name="password" placeholder="mot de passe" />
          <button className="loginButton" type="submit" value="Se connecter" >Se connecter</button>
          <span>
           Pas encore de compte? <Link to={"/register"}><b>S'inscrire.</b></Link>
          </span>
        </form>
      </div>
    </div>
  );
}
function isCorrect(mail,mdp){

  if(mail==="j.decker@cirep.be"||mail==="j.burnotte@cirep.be"||mail==="s.dubru@cirep.be"||mail==="e.georgoulis@wallopoly.be"){
   if(mdp==="leCirepCestChouette01!")
   {
     return true;
   }else{
     return false;
   }

  }return false;
}

