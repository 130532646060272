import { InfoOutlined, PlayArrow } from "@material-ui/icons";
import "./featured.scss";
import {Link} from "react-router-dom";

export default function Featured({ type }) {
  return (
    <div className="featured">
      {type && (
        <div className="category">
          <span>{type === "movie" ? "Movies" : "Series"}</span>
          <select name="genre" id="genre">
            <option>Genre</option>
            <option value="adventure">Adventure</option>
            <option value="comedy">Comedy</option>
            <option value="crime">Crime</option>
            <option value="fantasy">Fantasy</option>
            <option value="historical">Historical</option>
            <option value="horror">Horror</option>
            <option value="romance">Romance</option>
            <option value="sci-fi">Sci-fi</option>
            <option value="thriller">Thriller</option>
            <option value="western">Western</option>
            <option value="animation">Animation</option>
            <option value="drama">Drama</option>
            <option value="documentary">Documentary</option>
          </select>
        </div>
      )}
      <img
        src="virginthumbnail.png"
        alt=""
      />
      <div className="info">
        <img
          src="title.png"
          alt=""
        />
        <span className="desc">
         Dans cette série de visio-conférences,
          les divers intervenants du Cirep mettront en avant leurs connaissance du polyhandicap pour tenter d'apporter
          une mise à jours des savoirs concernant l'apprentissage chez l'enfant polyhandicapé.
        </span>
        <div className="buttons">
          <button className="play">
              <Link to='/watch'  state={{ url: "besoins"}}> <PlayArrow className="icon"/></Link>
            <span>Play</span>
          </button>
          {/*<button className="more">*/}
          {/*  <InfoOutlined />*/}
          {/*  <span>Info</span>*/}
          {/*</button>*/}
        </div>
      </div>
    </div>
  );
}
